import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { CheckInvitationCodeResponse } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';

export function useCheckInvitationCode(
  invitationCode: string | null,
  onSuccess?: (email: CheckInvitationCodeResponse) => void,
) {
  const { authService } = useServices();
  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  const result = useQuery<CheckInvitationCodeResponse, AppError>({
    queryKey: ['invitation-code', invitationCode],
    queryFn: () => authService.checkInvitationCode(invitationCode!),
    refetchOnWindowFocus: false,
    enabled: !!invitationCode,
  });

  useEffect(() => {
    if (result.isSuccess) {
      onSuccessRef.current?.(result.data);
    }
  }, [result.data, result.isSuccess]);

  return result;
}
