import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import { $isTableSelection } from '@lexical/table';
import { $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, styled, useTheme } from '@mui/material';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
} from 'lexical';
import React, { useState } from 'react';

import { useToolbarApi } from '../../context';
import { useToolbarUpdate } from '../../useToolbarUpdate';
import { BlockTypesDropDown } from './BlockTypesDropDown';
import { BlockType } from './block-type';
import { BlockName } from './common';

const StyledBlockTypesButton = styled(Button)(({ theme }) => ({
  minWidth: 130,
  color: theme.palette.common.white,
  textTransform: 'none',
  justifyContent: 'left',
  '& .MuiButton-endIcon': {
    position: 'absolute',
    right: theme.spacing(1.5),
  },
  '& .MuiButton-root': {
    width: '100%',
  },
}));

const StyledIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const BlockTypesMenu = () => {
  const { editor } = useToolbarApi();
  const theme = useTheme();

  const [currentBlockType, setCurrentBlockType] = useState<BlockType>(
    BlockType.paragraph,
  );

  // opens the dropdown at the position of the button
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleUnorderedList = () => {
    if (currentBlockType !== BlockType.unorderedList) {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const toggleOrderedList = () => {
    if (currentBlockType !== BlockType.unorderedList) {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (
    headingSize: BlockType.h1 | BlockType.h2 | BlockType.h3,
  ) => {
    if (currentBlockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || $isTableSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize));
        }
      });
    }
  };

  const onMenuItemClicked = (type: BlockType) => {
    switch (type) {
      case BlockType.paragraph:
        formatParagraph();
        break;
      case BlockType.h1:
      case BlockType.h2:
      case BlockType.h3:
        formatHeading(type);
        break;
      case BlockType.unorderedList:
        toggleUnorderedList();
        break;
      case BlockType.orderedList:
        toggleOrderedList();
        break;
    }

    handleClose();
  };

  // determine current block type
  useToolbarUpdate((editor, { selection }) => {
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        let type: BlockType;

        if ($isHeadingNode(element)) {
          type = element.getTag() as BlockType;
        } else if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          );
          const listType = parentList
            ? parentList.getListType()
            : element.getListType();
          type =
            listType === 'number'
              ? BlockType.orderedList
              : BlockType.unorderedList;
        } else {
          type = element.getType() as BlockType;
        }

        setCurrentBlockType(type);
      }
    }
  });

  return (
    <div style={{ position: 'relative' }}>
      <StyledBlockTypesButton
        size={'large'}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        endIcon={<StyledIcon />}
      >
        <BlockName
          blockType={currentBlockType}
          color={theme.palette.common.white}
        />
      </StyledBlockTypesButton>

      <BlockTypesDropDown
        onClose={handleClose}
        onMenuItemClicked={onMenuItemClicked}
        anchorEl={anchorEl}
      />
    </div>
  );
};
