import { IconButtonProps } from '@mui/material';
import React, { useState } from 'react';

import { OverflowIconButton } from '@/common/ui/buttons/overflow-action-menu/OverflowIconButton';
import { DropDownMenuItemElement, Dropdown } from '@/common/ui/dropdown';

interface Props {
  overflowActions: DropDownMenuItemElement[];
}

export const OverflowActionMenu = ({
  overflowActions,
  color,
  ...props
}: Props & Omit<IconButtonProps, 'onClick'>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenDropdown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    // e.stopPropagation() is not sufficient on OverflowIconButton, because the Dropdown expands over the whole site.
    <div onClick={(e) => e.stopPropagation()}>
      <OverflowIconButton
        color={color}
        onClick={(e) => {
          handleOpenDropdown(e);
        }}
        {...props}
      />
      <Dropdown
        onClose={handleClose}
        anchorEl={anchorEl}
        actions={overflowActions}
      />
    </div>
  );
};
