import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { PrintPayload } from '@/services/protocol';

export const useExportPdf = () => {
  const { printProtocolService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { mutate, isPending } = useMutation<
    void,
    AppError,
    { fileName: string; printPayload: PrintPayload }
  >({
    mutationFn: async ({ fileName, printPayload }) => {
      const blob = await printProtocolService.exportPDF(printPayload);
      if (blob) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.pdf`;
        link.click();
      }
    },

    onError: (err) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, err),
      });
    },
  });
  return {
    mutate,
    isLoading: isPending,
  };
};
