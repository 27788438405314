import { FirebaseError } from 'firebase/app';
import {
  User as FirebaseAuthUser,
  Unsubscribe,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';

import {
  CheckInvitationCodeResponse,
  ErrorType,
  RegisterPayload,
  UserRole,
} from '@ibag/common';

import { AuthUser } from '@/common/types/auth';
import { AppError } from '@/common/types/errors';
import { Logger } from '@/services/logger';

import { HttpClient } from '../http-client';
import { LoginRequest } from './types';

export class AuthService {
  private currentUser: AuthUser | null = null;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly auth = getAuth(),
  ) {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.currentUser = await convertToAuthUser(user);
        Logger.instance.setUser({
          id: user.uid,
          email: user.email ?? undefined,
        });
      } else {
        this.currentUser = null;
        Logger.instance.setUser(null);
      }
    });
  }

  async sendResetEmail(email: string) {
    try {
      return await sendPasswordResetEmail(this.auth, email);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw convertFirebaseErrorToAppError(error);
      } else {
        new AppError(ErrorType.UNKNOWN, (error as Error).message);
      }
    }
  }

  checkInvitationCode(
    invitationCode: string,
  ): Promise<CheckInvitationCodeResponse> {
    return this.httpClient.jsonRequest<CheckInvitationCodeResponse>(
      'GET',
      '/api/users/invitation',
      { code: invitationCode },
    );
  }

  async getToken(): Promise<string> {
    if (this.auth.currentUser) {
      return this.auth.currentUser.getIdToken();
    }

    Logger.instance.logWarning(
      'getToken: Current user not initialized, waiting for init...',
    );

    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        this.auth,
        async (user: FirebaseAuthUser | null) => {
          unsubscribe();
          if (user) {
            Logger.instance.log(
              'getToken: User now initialized, getting token...',
            );
            const token = await user.getIdToken();
            resolve(token);
          } else {
            Logger.instance.logWarning(
              'getToken: Could not get token, user initialization failed',
            );
            reject(
              new AppError(ErrorType.UNAUTHENTICATED, 'User is not logged in'),
            );
          }
        },
      );
    });
  }

  get user() {
    return this.currentUser;
  }

  onUserChanged(onUserChange: (user: AuthUser | null) => void): Unsubscribe {
    return onAuthStateChanged(
      this.auth,
      async (user: FirebaseAuthUser | null) => {
        if (user) {
          const authUser = await convertToAuthUser(user);
          onUserChange(authUser);
        } else {
          onUserChange(null);
        }
      },
    );
  }

  async login(loginRequest: LoginRequest): Promise<AuthUser> {
    try {
      //firebase
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        loginRequest.email,
        loginRequest.password,
      );
      return convertToAuthUser(userCredential.user);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw convertFirebaseErrorToAppError(error);
      } else {
        throw error;
      }
    }
  }

  async register(payload: RegisterPayload) {
    await this.httpClient.jsonRequest(
      'POST',
      '/api/users/register',
      undefined,
      payload,
    );
  }

  async logout() {
    try {
      await signOut(this.auth);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw convertFirebaseErrorToAppError(error);
      } else {
        throw error;
      }
    }
  }
}

const convertToAuthUser = async (user: FirebaseAuthUser): Promise<AuthUser> => {
  return {
    id: user.uid,
    email: user.email,
    displayName: user.displayName,
    role: getRoleOffline(user),
  };
};

/**
 * Parse the jwt token to get the role of the user.
 * Normally you would use user.getIdTokenResult(), but this requires a network
 * connection when the token is expired, so it can't be used offline.
 * Be aware that the role is extracted from a potentially expired token.
 */
const getRoleOffline = (user: FirebaseAuthUser): UserRole => {
  const token = (user.toJSON() as any).stsTokenManager?.accessToken;
  if (token) {
    return jwtDecode<{ role: UserRole }>(token).role;
  }
  return UserRole.USER;
};

export const convertFirebaseErrorToAppError = (
  error: FirebaseError,
): AppError => {
  switch (error.code) {
    case 'auth/user-not-found':
      return new AppError(ErrorType.USER_NOT_FOUND);
    case 'auth/wrong-password':
      return new AppError(ErrorType.INVALID_CREDENTIALS);
    case 'auth/email-already-exists':
      return new AppError(ErrorType.DUPLICATE_EMAIL);
    case 'auth/network-request-failed':
      return new AppError(ErrorType.NETWORK);
    default:
      return new AppError(ErrorType.UNKNOWN);
  }
};
