import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateUserPayload, UserRole } from '@ibag/common';

import { WideDialog } from '@/common/styles/wide-dialog';
import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';

import { UpsertUserForm } from '../UpsertUserForm';
import { useAddUser } from './useAddUser';

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
}

export default function AddUserDialog({ isDialogOpen, onClose }: Props) {
  const { t } = useTranslation();

  const {
    mutate: addUser,
    error: addUserError,
    isPending: isLoadingAddUser,
  } = useAddUser(onClose);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm<CreateUserPayload>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: UserRole.USER,
    },
  });

  const onSubmit: SubmitHandler<CreateUserPayload> = (data) => {
    addUser(data);
    reset();
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <WideDialog
      isOpen={isDialogOpen}
      onClose={handleOnClose}
      title={t('page.add_user.dialog_add.title')}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <SecondaryButton onClick={handleOnClose}>
              {t('page.add_user.dialog_add.cancel_button')}
            </SecondaryButton>
            <PrimaryButton
              loading={isLoadingAddUser}
              onClick={handleSubmit(onSubmit)}
            >
              {t('page.add_user.dialog_add.submit_button')}
            </PrimaryButton>
          </>
        );
      }}
    >
      <UpsertUserForm
        control={control}
        formErrors={formErrors}
        addUserError={addUserError}
      />
    </WideDialog>
  );
}
