import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import { Edit } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

import { useContact } from '../useContact';
import { EditContactDialog } from './EditContactDialog';

interface Props {
  id: string;
}

export const EditContactButton = React.memo(
  ({ id, ...props }: Props & IconButtonProps) => {
    const { t } = useTranslation();
    const { isOpen, close, open } = useDialog<void, void>();
    const contact = useContact(id);
    const variant = useProtocolState(
      (s: SigekoStore) => s.protocol.header.variant,
    );

    return (
      <>
        <Tooltip
          title={t(
            `page.edit_protocol.${variant}.header.inputs.mailing_list.edit_button_tooltip`,
          )}
        >
          <IconButton color={'primary'} onClick={() => open()} {...props}>
            <Edit />
          </IconButton>
        </Tooltip>
        {contact && (
          <EditContactDialog
            contactToEdit={contact}
            isDialogOpen={isOpen}
            onClose={close}
          />
        )}
      </>
    );
  },
);
