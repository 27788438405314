import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ProtocolStatus } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { BaseProtocol, Protocol } from '@/common/types/protocol';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { BaseStore, useProtocolState } from '@/pages/edit-protocol/common';
import {
  ChangeStatusTask,
  LockProtocolTask,
  MakeProtocolOfflineAvailableTask,
} from '@/services/database';

export function useProposeEditProtocolDialog(
  protocol: Protocol,
  onComplete?: () => void,
) {
  const changeProtocolNumber = useProtocolState(
    (s: BaseStore<BaseProtocol>) => s.changeProtocolNumber,
  );
  const [protocolNumber, replaceProtocol] = useProtocolState(
    (s: BaseStore<BaseProtocol>) => [
      s.protocol.header.protocolNumber,
      s.replaceProtocol,
    ],
  );

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { taskQueue, protocolService } = useServices();

  const { mutate, isPending } = useMutation<void, AppError>({
    mutationFn: async () => {
      // 1. increase protocol number
      changeProtocolNumber(protocolNumber + 1);

      // 2. change status to IN_EDITING
      await taskQueue.addTask(
        ChangeStatusTask.create(protocol.id, ProtocolStatus.IN_EDITING),
        { awaitResult: true },
      );

      // 3. lock protocol when not locked
      if (protocol.lockedBy === null) {
        await taskQueue.addTask(LockProtocolTask.create(protocol.id, true), {
          awaitResult: true,
        });
      }

      // 4. make protocol offline available when not offline available
      if (!protocol.offlineAvailable) {
        await taskQueue.addTask(
          MakeProtocolOfflineAvailableTask.create(protocol.id),
          { awaitResult: true },
        );
      }
    },

    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
      onComplete?.();
    },

    onSuccess: async () => {
      const updatedProtocol = await protocolService.fetchLocalProtocol(
        protocol.id,
      );
      updatedProtocol && replaceProtocol(updatedProtocol);
      onComplete?.();
    },
  });

  return {
    applyActions: mutate,
    isLoading: isPending,
  } as const;
}
