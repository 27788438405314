import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import React from 'react';

import { TemplateType } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { Template } from '@/common/types/template';

export function useSearchTemplates(search: string, type: TemplateType | null) {
  const { templateService } = useServices();

  // Debounce the search
  const [debouncedSearch, setDebouncedSearch] = React.useState(search);

  React.useEffect(() => {
    const handleDebounce = debounce((searchValue) => {
      setDebouncedSearch(searchValue);
    }, 300);

    handleDebounce(search);

    return () => {
      handleDebounce.cancel();
    };
  }, [search, type]);

  // implement search with react query
  const { data, error, isLoading } = useQuery<Template[], AppError>({
    queryKey: ['template-search', debouncedSearch, type],
    queryFn: async () => templateService.search(debouncedSearch, type),
    networkMode: 'always',
  });

  return {
    templates: data,
    error,
    isLoading,
  } as const;
}
