import { useMutation } from '@tanstack/react-query';

import { useServices } from '@/common/providers/service-provider';
import { AuthUser } from '@/common/types/auth';
import { AppError } from '@/common/types/errors';
import { LoginRequest } from '@/services/auth';

export const useLogin = (onSuccess: (user: AuthUser) => void) => {
  const { authService } = useServices();
  return useMutation<AuthUser, AppError, LoginRequest>({
    mutationFn: (loginRequest) => {
      return authService.login(loginRequest);
    },
    onSuccess,
  });
};
