import { styled } from '@mui/material';

import { RoundedContainer } from './RoundedContainer';

export const TableRoundedContainer = styled(RoundedContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 3),
  margin: theme.spacing(2, 0),
}));
