import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeadlinePreset } from '@ibag/common';

import { Deadline } from '@/common/types/protocol';
import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';
import { Dialog } from '@/common/ui/dialog';
import { useProtocolState } from '@/pages/edit-protocol/common';

import { SigekoStore } from '../../sigeko-store';

const DialogContent = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 0),
  display: 'flex',
  flexDirection: 'column',
}));

const TextBlocks = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
  defectId: string;
  deadline: Deadline | null;
}

export function SetDeadlineDialog({
  isDialogOpen,
  onClose,
  defectId,
  deadline,
}: Props) {
  const { t } = useTranslation();

  const [deadlineValue, setDeadlineValue] = useState<Deadline | null>(deadline);
  const setDeadline = useProtocolState((s: SigekoStore) => s.setDeadline);

  const handleSubmit = () => {
    setDeadline(defectId, deadlineValue);
    onClose();
  };

  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={onClose}
      title={t('page.edit_protocol.actions.add_deadline.dialog.title')}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <SecondaryButton onClick={onClose}>
              {t(
                'page.edit_protocol.actions.add_deadline.dialog.button_cancel',
              )}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit}>
              {t(
                'page.edit_protocol.actions.add_deadline.dialog.button_submit',
              )}
            </PrimaryButton>
          </>
        );
      }}
    >
      <DialogContent>
        <LocalizationProvider adapterLocale={'de'} dateAdapter={AdapterDayjs}>
          {/*Protocol Date*/}
          <DatePicker
            label={t(
              'page.edit_protocol.actions.add_deadline.dialog.label_date',
            )}
            value={deadlineValue instanceof Date ? dayjs(deadlineValue) : null}
            onChange={(newValue) => {
              setDeadlineValue(dayjs(newValue).toDate());
            }}
            slots={{ textField: TextField }}
          />
        </LocalizationProvider>
        <TextBlocks>
          {Object.values(DeadlinePreset).map((preset) => {
            return (
              <PrimaryButton
                key={preset}
                color={preset === deadlineValue ? 'primary' : 'secondary'}
                onClick={() => {
                  setDeadlineValue(preset);
                }}
              >
                {t(`common.deadline_presets.${preset}`)}
              </PrimaryButton>
            );
          })}
        </TextBlocks>
      </DialogContent>
    </Dialog>
  );
}
