import { Refresh } from '@mui/icons-material';
import { ListItemText } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { DropDownMenuItem, LoadingIcon } from '@/common/ui/dropdown';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { NetworkMode } from '@/services/database';

interface Props {
  // will be set, if this MenuItem is used in a dropdown component
  close?: () => void;
}

export const UpdateTemplatesMenuItem = ({ close }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { updateTemplatesService } = useServices();
  const { showSnackbar } = useSnackbar();

  const { isPending, mutate: updateTemplates } = useMutation<void, AppError>({
    mutationFn: () => {
      return updateTemplatesService.updateTemplates(NetworkMode.ONLINE);
    },

    networkMode: 'always',

    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
      close?.();
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['template-search'],
      });
      showSnackbar({
        variant: 'success',
        content: t('editor.insert_template.dialog.update_templates_success'),
      });
      close?.();
    },
  });

  return (
    <DropDownMenuItem onClick={() => updateTemplates()}>
      <LoadingIcon isLoading={isPending}>
        <Refresh />
      </LoadingIcon>
      <ListItemText>
        {t('editor.insert_template.dialog.update_templates')}
      </ListItemText>
    </DropDownMenuItem>
  );
};
