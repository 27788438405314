import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { GridColDef, gridClasses } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListTemplatesParams, TemplateType } from '@ibag/common';

import { useNotUndefined } from '@/common/hooks/use-not-undefined';
import { useUpdateTemplateType } from '@/common/hooks/use-update-template-type';
import { VisibilityChangeProvider } from '@/common/providers/visibility-change-provider';
import { StyledDataGrid } from '@/common/styles';
import { DeleteIconButton } from '@/common/ui/buttons';
import { TableRoundedContainer } from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';
import { TextEditor } from '@/common/ui/text-editor';

import { TemplateTableRow } from './types';
import { useDeleteTemplate } from './useDeleteTemplate';
import { useFetchTemplates } from './useFetchTemplates';

const ActionContainer = styled('div')(() => ({
  display: 'flex',
  gap: 10,
}));

const ContainerAlignTop = styled('div')(() => ({
  alignSelf: 'flex-start',
  justifyContent: 'center',
}));

interface TemplateTableProps {
  previewMode: boolean;
}

export const TemplateTable = ({ previewMode }: TemplateTableProps) => {
  const { t } = useTranslation();
  const [dataGridState, setDataGridState] = useState<ListTemplatesParams>({
    page: 0,
    pageSize: 25,
  });
  const { deleteTemplate, isLoading: isLoadingDeleteTemplate } =
    useDeleteTemplate();
  const {
    fetchedTemplates,
    isLoading: isLoadingFetchTemplates,
    error,
  } = useFetchTemplates(dataGridState.page, dataGridState.pageSize);

  const { updateTemplateType, isLoadingId } = useUpdateTemplateType();

  const rowCount = useNotUndefined(fetchedTemplates?.totalItems, 0);

  const columns: GridColDef<TemplateTableRow>[] = [
    {
      field: 'filename',
      flex: 1,
      headerName: t('page.template.template_table.columns.title'),
      renderCell: (params) => {
        return <ContainerAlignTop>{params.value}</ContainerAlignTop>;
      },
    },
    {
      field: 'type',
      flex: 1,
      maxWidth: 200,
      headerName: t('page.template.template_table.columns.type'),
      renderCell: (params) => {
        const loading = isLoadingId === params.row.id;
        return (
          <>
            <FormControl fullWidth variant={'standard'}>
              <Select
                value={params.value}
                onChange={(e) =>
                  updateTemplateType({
                    id: params.row.id,
                    type: e.target.value,
                  })
                }
                disabled={loading}
                renderValue={(value) => (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {t(`common.template_type.${value}`)}
                    {loading && <CircularProgress size={16} />}
                  </Box>
                )}
              >
                {Object.keys(TemplateType).map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {t(`common.template_type.${type}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );
      },
    },
    {
      field: 'rawText',
      sortable: false,
      headerName: t('page.template.template_table.columns.description'),
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {previewMode ? (
              <TextEditor
                onImagesSelected={() => console.log('')}
                initialContent={{ html: params.row.html }}
                readonly
              />
            ) : (
              <div
                style={{
                  maxHeight: 120,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {params.row.rawText as string}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: 'actions',
      sortable: false,
      headerName: t('page.template.template_table.columns.actions'),
      align: 'center',
      renderCell: (params) => {
        return (
          <ContainerAlignTop>
            <ActionContainer>
              <DeleteIconButton
                isLoading={isLoadingDeleteTemplate}
                onDelete={() => {
                  deleteTemplate(params.row.id);
                }}
              />
            </ActionContainer>
          </ContainerAlignTop>
        );
      },
    },
  ];

  return (
    <>
      <VisibilityChangeProvider>
        <TableRoundedContainer>
          <StyledDataGrid
            paginationMode={'server'}
            pagination
            loading={isLoadingFetchTemplates}
            disableColumnFilter
            initialState={{
              sorting: {
                sortModel: [{ field: 'active', sort: 'desc' }],
              },
            }}
            disableVirtualization
            getRowHeight={() => 'auto'}
            disableColumnMenu
            localeText={{
              noRowsLabel: t(`common.table.empty_table`),
              noResultsOverlayLabel: t(`common.table.empty_table`),
            }}
            pageSizeOptions={[]}
            rowCount={rowCount}
            paginationModel={{
              page: dataGridState.page,
              pageSize: dataGridState.pageSize,
            }}
            onPaginationModelChange={(newModel) =>
              setDataGridState((prev) => ({
                ...prev,
                page: newModel.page,
                pageSize: newModel.pageSize,
              }))
            }
            disableRowSelectionOnClick
            rows={fetchedTemplates?.items ?? []}
            columns={columns}
            sx={{
              [`& .${gridClasses.cell}`]: {
                // Problem: If you will show 2 lines of text, you need getRowHeight to return "auto".
                // This causes smaller padding, so we will need py:1, for the same look and feel as before.
                py: 1,
              },
            }}
          />
        </TableRoundedContainer>
        <ErrorMessage error={error} />
      </VisibilityChangeProvider>
    </>
  );
};
