import { styled } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WideDialog } from '@/common/styles';
import { Contact, ContactCategory } from '@/common/types/protocol';
import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

import { UpsertContactForm } from '../UpsertContactForm';
import { useAddContact } from './useAddContact';

const DialogContent = styled('div')(({ theme }) => ({
  margin: theme.spacing(5, 0),
  display: 'flex',
  flexDirection: 'column',
}));

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
}

export const AddContactDialog = ({ isDialogOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addContact } = useAddContact();

  const variant = useProtocolState(
    (s: SigekoStore) => s.protocol.header.variant,
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm<Contact>({
    defaultValues: {
      id: nanoid(),
      name: '',
      organisation: '',
      category: ContactCategory.CLIENT,
    },
  });

  // add a new Contact
  const onSubmit: SubmitHandler<Contact> = (newContact) => {
    addContact(newContact);
    reset({
      id: nanoid(),
      name: '',
      organisation: '',
      category: ContactCategory.CLIENT,
    });
    onClose();
  };

  return (
    <WideDialog
      isOpen={isDialogOpen}
      onClose={onClose}
      title={t(
        `page.edit_protocol.${variant}.header.inputs.add_contact_dialog.title`,
      )}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <SecondaryButton onClick={onClose}>
              {t(
                `page.edit_protocol.${variant}.header.inputs.add_contact_dialog.cancel_button`,
              )}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit(onSubmit)}>
              {t(
                `page.edit_protocol.${variant}.header.inputs.add_contact_dialog.submit_button`,
              )}
            </PrimaryButton>
          </>
        );
      }}
    >
      <DialogContent>
        <UpsertContactForm control={control} formErrors={formErrors} />
      </DialogContent>
    </WideDialog>
  );
};
