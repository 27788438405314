import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@/common/ui/buttons';
import { SpaceBetweenRowContainer } from '@/common/ui/containers';

import {
  Layout,
  ProposeEditProtocolDialog,
  ProtocolItemList,
  ProtocolTitle,
  useEditable,
  useProtocolState,
} from '../common';
import { SigekoProtocolDefect } from './SigekoProtocolDefect';
import { SigekoProtocolEndText } from './SigekoProtocolEndText';
import { SigekoProtocolStartText } from './SigekoProtocolStartText';
import { ProtocolHeader } from './header/ProtocolHeader';
import { selectProtocolDefects } from './selectors';
import { SigekoSideMenu } from './side-menu';
import { SigekoStore } from './sigeko-store';

export const SigekoProtocol = () => {
  const { t } = useTranslation();
  const editable = useEditable();

  const addDefect = useProtocolState((s: SigekoStore) => s.addDefect);
  const defects = useProtocolState((s: SigekoStore) =>
    selectProtocolDefects(s),
  );

  return (
    <Layout
      sidebar={
        <Layout.Sidebar>
          <SigekoSideMenu />
        </Layout.Sidebar>
      }
    >
      <Layout.ActionBar>
        <SpaceBetweenRowContainer>
          <ProtocolTitle />
        </SpaceBetweenRowContainer>
      </Layout.ActionBar>
      <Layout.Content>
        <ProtocolHeader />
        <SigekoProtocolStartText />
        <ProtocolItemList
          items={defects}
          ItemComponent={SigekoProtocolDefect}
        />
        <PrimaryButton
          sx={(theme) => ({ margin: theme.spacing(3, 0) })}
          startIcon={<AddCircleIcon />}
          onClick={() => addDefect()}
          disabled={!editable}
        >
          {t('page.edit_protocol.add_protocol_button')}
        </PrimaryButton>
        <SigekoProtocolEndText />
        <ProposeEditProtocolDialog />
      </Layout.Content>
    </Layout>
  );
};
