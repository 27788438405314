import { LockOpen, LockPerson } from '@mui/icons-material';
import { ListItemText } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolUserDto } from '@ibag/common';

import { useLockProtocol } from '@/common/hooks/use-lock-protocol';
import { DropDownMenuItem, LoadingIcon } from '@/common/ui/dropdown';

interface Props {
  protocolId: string;
  lockedBy: ProtocolUserDto | null;
  // Note: we do not specify onClick() but close() here, because we want to close the menu manually
  close?: () => void;
}

export const LockMenuItem = ({ lockedBy, protocolId, close }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { locked, canLock, canUnlock, lock, unlock, isLoading } =
    useLockProtocol(
      protocolId,
      lockedBy,
      () => {
        queryClient.invalidateQueries({
          queryKey: ['protocol-list']
        });
        close?.();
      },
      close,
    );

  return locked ? (
    <DropDownMenuItem disabled={!canUnlock} onClick={unlock}>
      <LoadingIcon isLoading={isLoading}>
        <LockOpen />
      </LoadingIcon>
      <ListItemText>{t('common.actions.unlock')}</ListItemText>
    </DropDownMenuItem>
  ) : (
    <DropDownMenuItem disabled={!canLock} onClick={lock}>
      <LoadingIcon isLoading={isLoading}>
        <LockPerson />
      </LoadingIcon>
      <ListItemText>{t('common.actions.lock')}</ListItemText>
    </DropDownMenuItem>
  );
};
