import { useMutation } from '@tanstack/react-query';

import { RegisterPayload } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';

export function useRegister(onSuccess?: () => void) {
  const { authService } = useServices();

  return useMutation<void, AppError, RegisterPayload>({
    mutationFn: async (registerRequest) => {
      await authService.register(registerRequest);
      await authService.login({
        email: registerRequest.email,
        password: registerRequest.password,
      });
    },
    onSuccess,
  });
}
