import {
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateUserPayload } from '@ibag/common';

import { useUser } from '@/common/hooks/use-user';
import { WideDialog } from '@/common/styles/wide-dialog';
import { PrimaryButton, SecondaryButton } from '@/common/ui/buttons';
import { Confirm } from '@/common/ui/confirm-dialog';

import { UpsertUserForm } from '../UpsertUserForm';
import { EditUserFormValues } from '../types';
import { useChangeUserStatus } from './useChangeUserStatus';
import { useEditUser } from './useEditUser';

const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'none',
}));

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
  userToEdit: EditUserFormValues;
  isUserActive: boolean;
}

export const EditUserDialog = ({
  isDialogOpen,
  onClose,
  userToEdit,
  isUserActive,
}: Props) => {
  const [active, setActive] = React.useState<boolean>(isUserActive);

  const {
    mutate: editUser,
    error: editUserError,
    isPending: isLoadingEditUser,
  } = useEditUser(userToEdit.id, onClose);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm<CreateUserPayload>({});
  const { mutate: changeUserStatus } = useChangeUserStatus(() => {
    setActive(!isUserActive);
  });

  const user = useUser();

  const onSubmit: SubmitHandler<CreateUserPayload> = (data) => {
    editUser(data);
  };

  const isUserHimself = user?.id === userToEdit.id;

  /*get all data to edit*/
  useEffect(() => {
    reset({ ...userToEdit });
  }, [reset, userToEdit]);

  return (
    <WideDialog
      isOpen={isDialogOpen}
      onClose={onClose}
      title={t('page.add_user.dialog_edit.title')}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <SecondaryButton color={'secondary'} onClick={onClose}>
              {t('page.add_user.dialog_edit.cancel_button')}
            </SecondaryButton>
            <PrimaryButton
              loading={isLoadingEditUser}
              onClick={handleSubmit(onSubmit)}
            >
              {t('page.add_user.dialog_edit.submit_button')}
            </PrimaryButton>
          </>
        );
      }}
    >
      <>
        <UpsertUserForm
          control={control}
          formErrors={formErrors}
          addUserError={editUserError}
        />

        <FormControl>
          <FormLabel
            sx={(theme) => ({
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(1),
            })}
          >
            {t('page.add_user.form.active_status_label')}
          </FormLabel>
          <Confirm
            title={t(
              'page.add_user.dialog_edit.toggle_button.confirm_dialog.title',
            )}
            text={t(
              'page.add_user.dialog_edit.toggle_button.confirm_dialog.description',
              {
                firstName: userToEdit.firstName,
                lastName: userToEdit.lastName,
              },
            )}
            cancelButtonTitle={t(
              'page.add_user.dialog_edit.toggle_button.confirm_dialog.cancel_button',
            )}
            confirmButtonTitle={t(
              'page.add_user.dialog_edit.toggle_button.confirm_dialog.submit_button',
            )}
            onConfirm={() => {
              changeUserStatus({
                id: userToEdit.id,
                active: !active,
              });
            }}
            closeOnError={true}
          >
            {(onPress) => (
              <ToggleButtonGroup
                disabled={isUserHimself}
                color="primary"
                value={active}
                exclusive
                onChange={onPress}
              >
                <StyledToggleButton value={true}>
                  {t('page.add_user.dialog_edit.toggle_button.active')}
                </StyledToggleButton>

                <StyledToggleButton value={false}>
                  {t('page.add_user.dialog_edit.toggle_button.inactive')}
                </StyledToggleButton>
              </ToggleButtonGroup>
            )}
          </Confirm>
        </FormControl>
      </>
    </WideDialog>
  );
};
