import { Stack, Typography, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Contact, ContactCategory } from '@/common/types/protocol';
import { useProtocolState } from '@/pages/edit-protocol/common';
import { SigekoStore } from '@/pages/edit-protocol/sigeko/sigeko-store';

interface Props {
  contacts: Contact[];
}

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
}));

export const MailingListSummary = ({ contacts }: Props) => {
  const { t } = useTranslation();
  const variant = useProtocolState(
    (s: SigekoStore) => s.protocol.header.variant,
  );
  const contactsByCategory = contacts.reduce(
    (acc, contact) => {
      acc[contact.category] += 1;
      acc.total += 1;
      return acc;
    },
    {
      [ContactCategory.CLIENT]: 0,
      [ContactCategory.CONTRACTOR]: 0,
      [ContactCategory.SUBCONTRACTOR]: 0,
      [ContactCategory.OTHER]: 0,
      total: 0,
    },
  );

  return (
    <Container>
      <Stack direction="row" spacing={1}>
        {Object.entries(contactsByCategory).map(([category, count]) => (
          <Typography variant={'body2'} key={category}>
            <strong>
              {t(
                `page.edit_protocol.${variant}.header.inputs.mailing_list.table.${category.toLowerCase()}`,
              )}
              :{' '}
            </strong>
            {count}
          </Typography>
        ))}
      </Stack>
    </Container>
  );
};
