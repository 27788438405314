import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { ListUser } from '@/common/types/user';

export const useFetchUsers = () => {
  const { userService } = useServices();

  const {
    data: fetchedUsers,
    isLoading,
    error: error,
  } = useQuery<ListUser[], AppError>({
    queryKey: ['users'],
    queryFn: async () => {
      return await userService.fetchUsers();
    },
  });

  return { fetchedUsers, isLoading, error } as const;
};
