import { useQuery } from '@tanstack/react-query';

import { Pagination } from '@ibag/common';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';

import { Template } from './types';

export const useFetchTemplates = (page: number, pageSize: number) => {
  const { templateService } = useServices();

  const {
    data: fetchedTemplates,
    isLoading,
    error: error,
  } = useQuery<Pagination<Template>, AppError>({
    queryKey: ['templates', page, pageSize],
    queryFn: async () => {
      return await templateService.fetchTemplates(page, pageSize);
    },
  });

  return { fetchedTemplates, isLoading, error };
};
