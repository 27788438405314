import { Delete } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { Confirm } from '@/common/ui/confirm-dialog';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';
import { useEditable } from '@/pages/edit-protocol/common';
import { routes } from '@/routing';
import { DeleteProtocolTask } from '@/services/database';

import { SideMenuButton } from '../SideMenuButton';

interface Props {
  protocolId: string;
}

export const DeleteProtocolButton = ({ protocolId }: Props) => {
  const { t } = useTranslation();
  const { taskQueue } = useServices();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const editable = useEditable();

  const { mutate, isPending } = useMutation<void, AppError>({
    mutationFn: () =>
      taskQueue.addTask(DeleteProtocolTask.create(protocolId), {
        awaitResult: true,
      }),
    networkMode: 'always',
    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        content: t('page.edit_protocol.side_menu.actions.delete_success'),
      });
      navigate(routes.HOME, { replace: true });
    },

    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
    },
  });

  return (
    <Confirm
      onConfirm={() => {
        mutate();
      }}
      title={t('page.edit_protocol.side_menu.actions.delete_confirm.title')}
      text={t('page.edit_protocol.side_menu.actions.delete_confirm.text')}
      closeOnError={true}
    >
      {(onPress) => (
        <SideMenuButton
          disabled={!editable}
          loading={isPending}
          onClick={onPress}
          icon={<Delete />}
        >
          {t('page.edit_protocol.side_menu.actions.delete_btn')}
        </SideMenuButton>
      )}
    </Confirm>
  );
};
