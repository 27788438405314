import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

interface UserActivationPayload {
  id: string;
  active: boolean;
}

export const useChangeUserStatus = (onSuccess: () => void) => {
  const { showSnackbar } = useSnackbar();
  const { userService } = useServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation<void, AppError, UserActivationPayload>({
    mutationFn: async ({ id, active }) =>
      await userService.changeUserStatus(id, active),

    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        content: t(
          'page.add_user.dialog_edit.user_activation.user_success_message',
        ),
      });
      onSuccess();
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },

    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
    },
  });
};
