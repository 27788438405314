import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

export const useSendResetEmail = (onSuccess: () => void) => {
  const { authService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation<void, AppError, string>({
    mutationFn: async (email) => {
      return authService.sendResetEmail(email);
    },
    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        content: t('page.reset.success_message'),
      });
      onSuccess();
    },
    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
    },
  });
};
