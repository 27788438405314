import { Alert, CircularProgress, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PrimaryButton } from '@/common/ui/buttons';
import { FormContainer } from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';
import { regex } from '@/common/utils/regex';
import { CenteredPage, RoundedAuthContainer } from '@/pages/auth/common';
import { HOME } from '@/routing/routes';

import { useCheckInvitationCode } from './useCheckInvitationCode';
import { useRegister } from './useRegister';

interface FormValues {
  email: string;
  password: string;
  passwordConfirm: string;
}

export const RegisterPage = () => {
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
  });

  const {
    mutate: register,
    isPending: isLoadingRegister,
    error: registerError,
  } = useRegister(() => {
    navigate(HOME, { replace: true });
  });

  const {
    data: email,
    isLoading: isLoadingCheckInviteCode,
    error: invitationCodeError,
  } = useCheckInvitationCode(
    searchParams.get('invitation-code'),
    (response) => {
      setValue('email', response.email);
    },
  );
  const isCodeValid = !!email;

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const invitationCode = searchParams.get('invitation-code');
    if (invitationCode) {
      register({
        email: data.email,
        password: data.password,
        invitationCode,
      });
    }
  };

  if (isLoadingCheckInviteCode) {
    return <CircularProgress />;
  }

  if (!isCodeValid) {
    return (
      <Alert severity="error">{t('page.register.link_invalid_text')}</Alert>
    );
  }

  return (
    <CenteredPage>
      <RoundedAuthContainer>
        {/*todo Logos einbauen <LogoSvg />*/}
        <Typography variant={'h1'}>{t('page.register.title')}</Typography>
        <form>
          <FormContainer>
            <Controller
              control={control}
              name={'email'}
              rules={{
                required: {
                  value: true,
                  message: t('common.validation.required'),
                },
                pattern: {
                  value: regex.email,
                  message: t('common.validation.email'),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  disabled
                  ref={ref}
                  autoCapitalize={'none'}
                  type={'email'}
                  label={t('page.register.email_label')}
                  autoComplete={'email'}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            <Controller
              control={control}
              name={'password'}
              rules={{
                required: {
                  value: true,
                  message: t('common.validation.required'),
                },
                minLength: {
                  value: 3,
                  message: t('common.validation.password.min'),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  ref={ref}
                  type={'password'}
                  autoComplete={'password'}
                  label={t('page.register.password_label')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={'passwordConfirm'}
              rules={{
                required: {
                  value: true,
                  message: t('common.validation.required'),
                },
                validate: (value) =>
                  value === getValues().password ||
                  t('common.validation.password.confirm').toString(),
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  ref={ref}
                  type={'password'}
                  label={t('page.register.password_confirm_label')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.passwordConfirm}
                  helperText={errors.passwordConfirm?.message}
                />
              )}
            />

            <ErrorMessage error={invitationCodeError || registerError} />

            <PrimaryButton
              loading={isLoadingRegister}
              onClick={handleSubmit(onSubmit)}
            >
              {t('page.register.submit_button')}
            </PrimaryButton>
          </FormContainer>
        </form>
      </RoundedAuthContainer>
    </CenteredPage>
  );
};
