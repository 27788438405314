import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/common/providers/service-provider';

export function useUpdateTemplates() {
  const { updateTemplatesService } = useServices();

  return useQuery({
    queryKey: ['update-template'],
    queryFn: async () => {
      await updateTemplatesService.updateTemplatesWhenNecessary();
      return true; // we need to return something
    },
    networkMode: 'always',
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    gcTime: Infinity,
  });
}
