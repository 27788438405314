import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

export const useSendInvitation = () => {
  const { showSnackbar } = useSnackbar();
  const { userService } = useServices();
  const { t } = useTranslation();
  return useMutation<void, AppError, string>({
    mutationFn: async (id) => await userService.inviteUser(id),

    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        content: t('page.add_user.send_invitation.on_success_message'),
      });
    },

    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
    },
  });
};
