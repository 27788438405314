import 'dayjs/locale/de';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './de.json';

/*Problem: in v12.1.1 problems null/string
Workaround: https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz*/
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const initI18n = () => {
  return i18next.use(initReactI18next).init({
    compatibilityJSON: 'v4',
    lng: 'de',
    resources: {
      de: {
        translation: de,
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss =>
      // https://www.i18next.com/translation-function/interpolation#unescape
    },
    returnNull: false, //see workaround
  });
};
