import { styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useServices } from '@/common/providers/service-provider';
import { AppError } from '@/common/types/errors';
import { RefreshButton } from '@/common/ui/buttons';
import { useSnackbar } from '@/common/ui/snackbar';
import { createErrorMessage } from '@/common/utils/errors';

const StyledRefreshButton = styled(RefreshButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.common.white,
}));

export const SyncProtocolsButton = () => {
  const { t } = useTranslation();

  const { syncProtocolsService } = useServices();
  const { showSnackbar } = useSnackbar();

  const { isPending, mutate } = useMutation<void, AppError, void>({
    mutationFn: () => {
      return syncProtocolsService.syncProtocols();
    },

    networkMode: 'always',

    onSuccess: () => {
      showSnackbar({
        content: t('page.home.sync_protocols.success'),
        variant: 'success',
      });
    },

    onError: (error) => {
      showSnackbar({
        variant: 'error',
        content: createErrorMessage(t, error),
      });
    },
  });

  return (
    <StyledRefreshButton
      onClick={() => mutate()}
      disabled={isPending}
      loading={isPending}
    />
  );
};
