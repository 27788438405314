import { Button, CircularProgress } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreApi, useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { useLiveProtocol } from '@/common/hooks/use-live-protocol';
import { MenuLayout } from '@/common/layout/MenuLayout';
import { Protocol } from '@/common/types/protocol';
import { CenteredContainer } from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';

import { useEditProtocol } from './hooks';
import { BaseStore } from './store';

interface StoreHolder {
  store: StoreApi<BaseStore<any>>;
  protocol: Readonly<Protocol>;
}

const ProtocolContext = React.createContext<StoreHolder | null>(null);

interface Props {
  protocolId: string;
  children: React.ReactNode;
}

export const ProtocolProvider = ({ protocolId, children }: Props) => {
  const { t } = useTranslation();

  const { isLoading, store, error, prepareProtocol } =
    useEditProtocol(protocolId);

  const protocol = useLiveProtocol(protocolId);

  if (isLoading || error) {
    return (
      <MenuLayout>
        <CenteredContainer>
          {isLoading && <CircularProgress color={'info'} />}
          {error && (
            <ErrorMessage
              error={error}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => prepareProtocol()}
                >
                  {t('common.actions.retry')}
                </Button>
              }
            />
          )}
        </CenteredContainer>
      </MenuLayout>
    );
  }

  if (store && protocol) {
    return (
      <ProtocolContext.Provider value={{ store, protocol }}>
        {children}
      </ProtocolContext.Provider>
    );
  }

  return null;
};

export const useProtocolState = function <S extends BaseStore<any>, U>(
  selector: (state: S) => U,
) {
  const context = useContext(ProtocolContext);
  if (context === null) {
    throw new Error(
      'ProtocolContext is null, wrap your component with a ProtocolProvider',
    );
  }

  return useStore(context.store as StoreApi<S>, useShallow(selector));
};

/**
 * Returns the plain protocol for read-only access
 * This comes handy when you want to access properties that are not part of the store
 */
export const useProtocol = function () {
  const context = useContext(ProtocolContext);
  if (context === null) {
    throw new Error(
      'ProtocolContext is null, wrap your component with a ProtocolProvider',
    );
  }

  return context.protocol;
};
