import { Link as MuiLink, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@/common/ui/buttons';
import { FormContainer } from '@/common/ui/containers';
import { ErrorMessage } from '@/common/ui/error-message';
import { regex } from '@/common/utils/regex';
import { RoundedAuthContainer } from '@/pages/auth/common/RoundedAuthContainer';
import { routes } from '@/routing';

import { CenteredPage } from '../common';
import { useLogin } from './useLogin';

interface FormValues {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    mutate: login,
    isPending: isLoadingLogin,
    error: loginError,
  } = useLogin(() => {
    navigate(routes.HOME, { replace: true });
  });

  const onSubmit: SubmitHandler<FormValues> = (formValues) => {
    login({
      email: formValues.email,
      password: formValues.password,
    });
  };

  return (
    <CenteredPage>
      <RoundedAuthContainer>
        <Typography variant={'h1'}>{t('page.login.title')}</Typography>
        <form>
          <FormContainer>
            <Controller
              control={control}
              name={'email'}
              rules={{
                required: {
                  value: true,
                  message: t('common.validation.required'),
                },
                pattern: {
                  value: regex.email,
                  message: t('common.validation.email'),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  ref={ref}
                  autoCapitalize={'none'}
                  type={'email'}
                  label={t('page.login.email_label')}
                  autoComplete={'email'}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            <Controller
              control={control}
              name={'password'}
              rules={{
                required: {
                  value: true,
                  message: t('common.validation.required'),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  ref={ref}
                  type={'password'}
                  autoComplete={'password'}
                  label={t('page.login.password_label')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />

            <ErrorMessage error={loginError} />

            <PrimaryButton
              loading={isLoadingLogin}
              onClick={handleSubmit(onSubmit)}
            >
              {t('page.login.submit_button')}
            </PrimaryButton>
            <MuiLink
              component={Link}
              variant={'caption'}
              to={routes.FORGOT_PASSWORD}
            >
              {t('page.login.forgot_password')}
            </MuiLink>
          </FormContainer>
        </form>
      </RoundedAuthContainer>
    </CenteredPage>
  );
};
