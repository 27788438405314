import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/common/providers/service-provider';

/*
 * Sync templates once on app start
 */
export function useSyncTemplates() {
  const { syncProtocolsService } = useServices();

  return useQuery({
    queryKey: ['sync-protocols'],
    queryFn: async () => {
      await syncProtocolsService.syncProtocols();
      return true; // we need to return something
    },
    networkMode: 'always',
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    gcTime: Infinity,
  });
}
